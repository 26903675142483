import React from "react";
import annPng from "../../../assetes/annotation.png";
import audioPng from "../../../assetes/audino-ai.png";
import customPng from "../../../assetes/CustomModel.png";
import dataPng from "../../../assetes/Database.png";
import { Link } from "react-router-dom";

function Card({ title, image, text, Path }) {
  return (
    <div className="max-w-[20rem] bg-[#22295B]  px-7 py-4  rounded-xl shadow-lg  hover:scale-105 transition duration-500 hover:border-1 hover:border-[#70CBA2] ">
      <div className="mx-auto w-32 h-32 mt-4 border-4 border-white rounded-full ">
        <img
          className="w-full rounded-full h-full object-cover"
          src={image}
          alt="/"
          width={"100%"}
          height={"100%"}
        />
      </div>
      <h1 className="mt-4 text-[#70CBA2] text-[16px] text-center font-bold cursor-pointer group-hover:text-orange-300">
        {title}
      </h1>
      <div className="mt-4">
        <div className="flex space-x-1 items-center">
          <p className="text-center font-light text-[13px]">{text}</p>
        </div>
        <Link to={Path}>
          <button
            button
            className="mt-4 text-xl w-full text-[#70CBA2] group-hover:text-orange-300  py-2 rounded-xl shadow-[16px]"
          >
            Explore
          </button>
        </Link>
      </div>
    </div>
  );
}

const Cards = () => {
  return (
    <section className="bg-[#0F1338] pt-10 sm:pt-0  text-white ">
      <div className=" flex justify-center pb-4 items-center md:mx-14 ">
        <div className="md:px-4 sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-4 space-y-3 sm:space-y-0">
          <Card
            image={annPng}
            title="AI Audino Annotation Tool"
            text="Free tool for audio annotation. Add labels for Voice Activity Detection, Speaker Identification, and more. Simplify tasks like transcription and Emotion Recognition."
            Path="https://app.audino.in"
          />
          <Card
            image={audioPng}
            title="Audino AI"
            text="The Playground of Audio and Video Magic. Step into our playground of AI wizardry, where audio becomes text, and video turns into concise gems. Elevate your content experience today."
            Path="https://ai.audino.in/"
          />
          <Card
            image={dataPng}
            title="Datasetes"
            text="Audino curates vast Indian language datasets for AI applications. Dive into a linguistic treasure trove, exploring nuances with Audino's diverse collection, enriching language-based applications."
            Path="/contact"
          />
          <Card
            image={customPng}
            title="Custom Model"
            text="Leverage Audino for powerful solutions. Share your challenges, and we'll create custom AI models for effective, innovative, and precise solutions tailored to your unique needs."
            Path="/custom-model"
          />
        </div>
      </div>
    </section>
  );
};

export default Cards;
