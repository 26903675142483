import React from "react";

export const ListItem = ({ title, text }) => {
  return (
    <li>
      <span className="font-semibold text-lg lg:text-[17px]"> {title} </span>
      <p className="ml-4  text-[15px] lg:text-[17px] leading-loose font-light text-slate-300 mt-2">
        {text}
      </p>
    </li>
  );
};
