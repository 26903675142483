import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Hero from "./components/Hero";

import Unifiedplatform from "./components/Unifiedplatform";
import Api from "./components/Api";
import Language from "./components/Language";
import Testinomial from "./components/Testinomial";

import { Blog } from "./components/Blog";
import Cards from "./components/Cards";

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <Cards />
      <Unifiedplatform />
      <Api />
      <Language />
      <Testinomial />
      <Blog />
      <Footer />
    </>
  );
}
