import React from "react";
import { AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import Speechtotext from "../../../assetes/speech-to-text.jpg";
import SpeekerIdentification from "../../../assetes/speekerIdentification.jpg";
import Speechsegmentation from "../../../assetes/SpeechSegmentation.jpg";
import BlogCard from "../../../components/BlogCard";

export const Blog = () => {
  return (
    <section className=" bg-[#0F1338] text-white py-10 lg:py-">
      <div className="max-w-[1170px] mx-auto px-4 sm:px-8 xl:px-0">
        <div className=" mb-16 text-center visible">
          <span className="  mb-4 font-thin text-sm inline-flex   px-4 shadow-[#70CBA2] shadow-sm items-center gap-2 py-2 px-4.5 rounded-full">
            <AiFillStar className="text-yellow-500 " />

            <span className="hero-subtitle-text">
              {" "}
              Read Our Latest Articles{" "}
            </span>
          </span>
          <h2 className="text-white mb-4 text-2xl font-extrabold sm:text-4xl xl:text-heading-2">
            Latest Blogs &amp; Articals
          </h2>
          <p className="max-w-[714px] mx-auto text-gray-200  font-thin">
            Our AI tool is designed to empower you with exceptional Lerning
            capabilities, making the Lerning ,texting process more efficient,
            accurate, and enjoyable.
          </p>
        </div>

        <Link
          to="/blogs"
          className="flex justify-end mr-3 font-thin hover:underline hover:text-blue-300"
        >
          View All Articles
        </Link>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
          <BlogCard
            title="The Evolution and Impact of Speech-to-Text Technology"
            description="In an age where convenience and efficiency are paramount, speech-to-text technology has become a transformative force across various industries"
            image={Speechtotext}
            href="/blogs/1"
            time={4}
          />

          <BlogCard
            title="Speaker Identification: The Voice Behind the Technology"
            description="In a world where technology is increasingly driven by voice interfaces, speaker identification is a critical component powering personalised and secure experiences."
            image={SpeekerIdentification}
            href="/blogs/2"
            time={3}
          />

          <BlogCard
            title="The Key to Unlocking Effective Communication Technologies"
            description="Speech segmentation is a crucial process in the field of speech and language processing. It involves dividing continuous speech into distinct units such as words, phonemes."
            image={Speechsegmentation}
            href="/blogs/3"
            time={3}
          />
        </div>
      </div>
    </section>
  );
};
