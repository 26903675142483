const countryvalueOptions = [
  {
    value: "Afghanistan",
    label: "+93",
    name: "AF",
  },
  {
    value: "Aland Islands",
    label: "+358",
    name: "AX",
  },
  {
    value: "Albania",
    label: "+355",
    name: "AL",
  },
  {
    value: "Algeria",
    label: "+213",
    name: "DZ",
  },
  {
    value: "AmericanSamoa",
    label: "+1684",
    name: "AS",
  },
  {
    value: "Andorra",
    label: "+376",
    name: "AD",
  },
  {
    value: "Angola",
    label: "+244",
    name: "AO",
  },
  {
    value: "Anguilla",
    label: "+1264",
    name: "AI",
  },
  {
    value: "Antarctica",
    label: "+672",
    name: "AQ",
  },
  {
    value: "Antigua and Barbuda",
    label: "+1268",
    name: "AG",
  },
  {
    value: "Argentina",
    label: "+54",
    name: "AR",
  },
  {
    value: "Armenia",
    label: "+374",
    name: "AM",
  },
  {
    value: "Aruba",
    label: "+297",
    name: "AW",
  },
  {
    value: "Australia",
    label: "+61",
    name: "AU",
  },
  {
    value: "Austria",
    label: "+43",
    name: "AT",
  },
  {
    value: "Azerbaijan",
    label: "+994",
    name: "AZ",
  },
  {
    value: "Bahamas",
    label: "+1242",
    name: "BS",
  },
  {
    value: "Bahrain",
    label: "+973",
    name: "BH",
  },
  {
    value: "Bangladesh",
    label: "+880",
    name: "BD",
  },
  {
    value: "Barbados",
    label: "+1246",
    name: "BB",
  },
  {
    value: "Belarus",
    label: "+375",
    name: "BY",
  },
  {
    value: "Belgium",
    label: "+32",
    name: "BE",
  },
  {
    value: "Belize",
    label: "+501",
    name: "BZ",
  },
  {
    value: "Benin",
    label: "+229",
    name: "BJ",
  },
  {
    value: "Bermuda",
    label: "+1441",
    name: "BM",
  },
  {
    value: "Bhutan",
    label: "+975",
    name: "BT",
  },
  {
    value: "Bolivia, Plurinational State of",
    label: "+591",
    name: "BO",
  },
  {
    value: "Bosnia and Herzegovina",
    label: "+387",
    name: "BA",
  },
  {
    value: "Botswana",
    label: "+267",
    name: "BW",
  },
  {
    value: "Brazil",
    label: "+55",
    name: "BR",
  },
  {
    value: "British Indian Ocean Territory",
    label: "+246",
    name: "IO",
  },
  {
    value: "Brunei Darussalam",
    label: "+673",
    name: "BN",
  },
  {
    value: "Bulgaria",
    label: "+359",
    name: "BG",
  },
  {
    value: "Burkina Faso",
    label: "+226",
    name: "BF",
  },
  {
    value: "Burundi",
    label: "+257",
    name: "BI",
  },
  {
    value: "Cambodia",
    label: "+855",
    name: "KH",
  },
  {
    value: "Cameroon",
    label: "+237",
    name: "CM",
  },
  {
    value: "Canada",
    label: "+1",
    name: "CA",
  },
  {
    value: "Cape Verde",
    label: "+238",
    name: "CV",
  },
  {
    value: "Cayman Islands",
    label: "+ 345",
    name: "KY",
  },
  {
    value: "Central African Republic",
    label: "+236",
    name: "CF",
  },
  {
    value: "Chad",
    label: "+235",
    name: "TD",
  },
  {
    value: "Chile",
    label: "+56",
    name: "CL",
  },
  {
    value: "China",
    label: "+86",
    name: "CN",
  },
  {
    value: "Christmas Island",
    label: "+61",
    name: "CX",
  },
  {
    value: "Cocos (Keeling) Islands",
    label: "+61",
    name: "CC",
  },
  {
    value: "Colombia",
    label: "+57",
    name: "CO",
  },
  {
    value: "Comoros",
    label: "+269",
    name: "KM",
  },
  {
    value: "Congo",
    label: "+242",
    name: "CG",
  },
  {
    value: "Congo, The Democratic Republic of the Congo",
    label: "+243",
    name: "CD",
  },
  {
    value: "Cook Islands",
    label: "+682",
    name: "CK",
  },
  {
    value: "Costa Rica",
    label: "+506",
    name: "CR",
  },
  {
    value: "Cote d'Ivoire",
    label: "+225",
    name: "CI",
  },
  {
    value: "Croatia",
    label: "+385",
    name: "HR",
  },
  {
    value: "Cuba",
    label: "+53",
    name: "CU",
  },
  {
    value: "Cyprus",
    label: "+357",
    name: "CY",
  },
  {
    value: "Czech Republic",
    label: "+420",
    name: "CZ",
  },
  {
    value: "Denmark",
    label: "+45",
    name: "DK",
  },
  {
    value: "Djibouti",
    label: "+253",
    name: "DJ",
  },
  {
    value: "Dominica",
    label: "+1767",
    name: "DM",
  },
  {
    value: "Dominican Republic",
    label: "+1849",
    name: "DO",
  },
  {
    value: "Ecuador",
    label: "+593",
    name: "EC",
  },
  {
    value: "Egypt",
    label: "+20",
    name: "EG",
  },
  {
    value: "El Salvador",
    label: "+503",
    name: "SV",
  },
  {
    value: "Equatorial Guinea",
    label: "+240",
    name: "GQ",
  },
  {
    value: "Eritrea",
    label: "+291",
    name: "ER",
  },
  {
    value: "Estonia",
    label: "+372",
    name: "EE",
  },
  {
    value: "Ethiopia",
    label: "+251",
    name: "ET",
  },
  {
    value: "Falkland Islands (Malvinas)",
    label: "+500",
    name: "FK",
  },
  {
    value: "Faroe Islands",
    label: "+298",
    name: "FO",
  },
  {
    value: "Fiji",
    label: "+679",
    name: "FJ",
  },
  {
    value: "Finland",
    label: "+358",
    name: "FI",
  },
  {
    value: "France",
    label: "+33",
    name: "FR",
  },
  {
    value: "French Guiana",
    label: "+594",
    name: "GF",
  },
  {
    value: "French Polynesia",
    label: "+689",
    name: "PF",
  },
  {
    value: "Gabon",
    label: "+241",
    name: "GA",
  },
  {
    value: "Gambia",
    label: "+220",
    name: "GM",
  },
  {
    value: "Georgia",
    label: "+995",
    name: "GE",
  },
  {
    value: "Germany",
    label: "+49",
    name: "DE",
  },
  {
    value: "Ghana",
    label: "+233",
    name: "GH",
  },
  {
    value: "Gibraltar",
    label: "+350",
    name: "GI",
  },
  {
    value: "Greece",
    label: "+30",
    name: "GR",
  },
  {
    value: "Greenland",
    label: "+299",
    name: "GL",
  },
  {
    value: "Grenada",
    label: "+1473",
    name: "GD",
  },
  {
    value: "Guadeloupe",
    label: "+590",
    name: "GP",
  },
  {
    value: "Guam",
    label: "+1671",
    name: "GU",
  },
  {
    value: "Guatemala",
    label: "+502",
    name: "GT",
  },
  {
    value: "Guernsey",
    label: "+44",
    name: "GG",
  },
  {
    value: "Guinea",
    label: "+224",
    name: "GN",
  },
  {
    value: "Guinea-Bissau",
    label: "+245",
    name: "GW",
  },
  {
    value: "Guyana",
    label: "+595",
    name: "GY",
  },
  {
    value: "Haiti",
    label: "+509",
    name: "HT",
  },
  {
    value: "Holy See (Vatican City State)",
    label: "+379",
    name: "VA",
  },
  {
    value: "Honduras",
    label: "+504",
    name: "HN",
  },
  {
    value: "Hong Kong",
    label: "+852",
    name: "HK",
  },
  {
    value: "Hungary",
    label: "+36",
    name: "HU",
  },
  {
    value: "Iceland",
    label: "+354",
    name: "IS",
  },
  {
    value: "India",
    label: "+91",
    name: "IN",
  },
  {
    value: "Indonesia",
    label: "+62",
    name: "ID",
  },
  {
    value: "Iran, Islamic Republic of Persian Gulf",
    label: "+98",
    name: "IR",
  },
  {
    value: "Iraq",
    label: "+964",
    name: "IQ",
  },
  {
    value: "Ireland",
    label: "+353",
    name: "IE",
  },
  {
    value: "Isle of Man",
    label: "+44",
    name: "IM",
  },
  {
    value: "Israel",
    label: "+972",
    name: "IL",
  },
  {
    value: "Italy",
    label: "+39",
    name: "IT",
  },
  {
    value: "Jamaica",
    label: "+1876",
    name: "JM",
  },
  {
    value: "Japan",
    label: "+81",
    name: "JP",
  },
  {
    value: "Jersey",
    label: "+44",
    name: "JE",
  },
  {
    value: "Jordan",
    label: "+962",
    name: "JO",
  },
  {
    value: "Kazakhstan",
    label: "+77",
    name: "KZ",
  },
  {
    value: "Kenya",
    label: "+254",
    name: "KE",
  },
  {
    value: "Kiribati",
    label: "+686",
    name: "KI",
  },
  {
    value: "Korea, Democratic People's Republic of Korea",
    label: "+850",
    name: "KP",
  },
  {
    value: "Korea, Republic of South Korea",
    label: "+82",
    name: "KR",
  },
  {
    value: "Kuwait",
    label: "+965",
    name: "KW",
  },
  {
    value: "Kyrgyzstan",
    label: "+996",
    name: "KG",
  },
  {
    value: "Laos",
    label: "+856",
    name: "LA",
  },
  {
    value: "Latvia",
    label: "+371",
    name: "LV",
  },
  {
    value: "Lebanon",
    label: "+961",
    name: "LB",
  },
  {
    value: "Lesotho",
    label: "+266",
    name: "LS",
  },
  {
    value: "Liberia",
    label: "+231",
    name: "LR",
  },
  {
    value: "Libyan Arab Jamahiriya",
    label: "+218",
    name: "LY",
  },
  {
    value: "Liechtenstein",
    label: "+423",
    name: "LI",
  },
  {
    value: "Lithuania",
    label: "+370",
    name: "LT",
  },
  {
    value: "Luxembourg",
    label: "+352",
    name: "LU",
  },
  {
    value: "Macao",
    label: "+853",
    name: "MO",
  },
  {
    value: "Macedonia",
    label: "+389",
    name: "MK",
  },
  {
    value: "Madagascar",
    label: "+261",
    name: "MG",
  },
  {
    value: "Malawi",
    label: "+265",
    name: "MW",
  },
  {
    value: "Malaysia",
    label: "+60",
    name: "MY",
  },
  {
    value: "Maldives",
    label: "+960",
    name: "MV",
  },
  {
    value: "Mali",
    label: "+223",
    name: "ML",
  },
  {
    value: "Malta",
    label: "+356",
    name: "MT",
  },
  {
    value: "Marshall Islands",
    label: "+692",
    name: "MH",
  },
  {
    value: "Martinique",
    label: "+596",
    name: "MQ",
  },
  {
    value: "Mauritania",
    label: "+222",
    name: "MR",
  },
  {
    value: "Mauritius",
    label: "+230",
    name: "MU",
  },
  {
    value: "Mayotte",
    label: "+262",
    name: "YT",
  },
  {
    value: "Mexico",
    label: "+52",
    name: "MX",
  },
  {
    value: "Micronesia, Federated States of Micronesia",
    label: "+691",
    name: "FM",
  },
  {
    value: "Moldova",
    label: "+373",
    name: "MD",
  },
  {
    value: "Monaco",
    label: "+377",
    name: "MC",
  },
  {
    value: "Mongolia",
    label: "+976",
    name: "MN",
  },
  {
    value: "Montenegro",
    label: "+382",
    name: "ME",
  },
  {
    value: "Montserrat",
    label: "+1664",
    name: "MS",
  },
  {
    value: "Morocco",
    label: "+212",
    name: "MA",
  },
  {
    value: "Mozambique",
    label: "+258",
    name: "MZ",
  },
  {
    value: "Myanmar",
    label: "+95",
    name: "MM",
  },
  {
    value: "Namibia",
    label: "+264",
    name: "NA",
  },
  {
    value: "Nauru",
    label: "+674",
    name: "NR",
  },
  {
    value: "Nepal",
    label: "+977",
    name: "NP",
  },
  {
    value: "Netherlands",
    label: "+31",
    name: "NL",
  },
  {
    value: "Netherlands Antilles",
    label: "+599",
    name: "AN",
  },
  {
    value: "New Caledonia",
    label: "+687",
    name: "NC",
  },
  {
    value: "New Zealand",
    label: "+64",
    name: "NZ",
  },
  {
    value: "Nicaragua",
    label: "+505",
    name: "NI",
  },
  {
    value: "Niger",
    label: "+227",
    name: "NE",
  },
  {
    value: "Nigeria",
    label: "+234",
    name: "NG",
  },
  {
    value: "Niue",
    label: "+683",
    name: "NU",
  },
  {
    value: "Norfolk Island",
    label: "+672",
    name: "NF",
  },
  {
    value: "Northern Mariana Islands",
    label: "+1670",
    name: "MP",
  },
  {
    value: "Norway",
    label: "+47",
    name: "NO",
  },
  {
    value: "Oman",
    label: "+968",
    name: "OM",
  },
  {
    value: "Pakistan",
    label: "+92",
    name: "PK",
  },
  {
    value: "Palau",
    label: "+680",
    name: "PW",
  },
  {
    value: "Palestinian Territory, Occupied",
    label: "+970",
    name: "PS",
  },
  {
    value: "Panama",
    label: "+507",
    name: "PA",
  },
  {
    value: "Papua New Guinea",
    label: "+675",
    name: "PG",
  },
  {
    value: "Paraguay",
    label: "+595",
    name: "PY",
  },
  {
    value: "Peru",
    label: "+51",
    name: "PE",
  },
  {
    value: "Philippines",
    label: "+63",
    name: "PH",
  },
  {
    value: "Pitcairn",
    label: "+872",
    name: "PN",
  },
  {
    value: "Poland",
    label: "+48",
    name: "PL",
  },
  {
    value: "Portugal",
    label: "+351",
    name: "PT",
  },
  {
    value: "Puerto Rico",
    label: "+1939",
    name: "PR",
  },
  {
    value: "Qatar",
    label: "+974",
    name: "QA",
  },
  {
    value: "Romania",
    label: "+40",
    name: "RO",
  },
  {
    value: "Russia",
    label: "+7",
    name: "RU",
  },
  {
    value: "Rwanda",
    label: "+250",
    name: "RW",
  },
  {
    value: "Reunion",
    label: "+262",
    name: "RE",
  },
  {
    value: "Saint Barthelemy",
    label: "+590",
    name: "BL",
  },
  {
    value: "Saint Helena, Ascension and Tristan Da Cunha",
    label: "+290",
    name: "SH",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "+1869",
    name: "KN",
  },
  {
    value: "Saint Lucia",
    label: "+1758",
    name: "LC",
  },
  {
    value: "Saint Martin",
    label: "+590",
    name: "MF",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "+508",
    name: "PM",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "+1784",
    name: "VC",
  },
  {
    value: "Samoa",
    label: "+685",
    name: "WS",
  },
  {
    value: "San Marino",
    label: "+378",
    name: "SM",
  },
  {
    value: "Sao Tome and Principe",
    label: "+239",
    name: "ST",
  },
  {
    value: "Saudi Arabia",
    label: "+966",
    name: "SA",
  },
  {
    value: "Senegal",
    label: "+221",
    name: "SN",
  },
  {
    value: "Serbia",
    label: "+381",
    name: "RS",
  },
  {
    value: "Seychelles",
    label: "+248",
    name: "SC",
  },
  {
    value: "Sierra Leone",
    label: "+232",
    name: "SL",
  },
  {
    value: "Singapore",
    label: "+65",
    name: "SG",
  },
  {
    value: "Slovakia",
    label: "+421",
    name: "SK",
  },
  {
    value: "Slovenia",
    label: "+386",
    name: "SI",
  },
  {
    value: "Solomon Islands",
    label: "+677",
    name: "SB",
  },
  {
    value: "Somalia",
    label: "+252",
    name: "SO",
  },
  {
    value: "South Africa",
    label: "+27",
    name: "ZA",
  },
  {
    value: "South Sudan",
    label: "+211",
    name: "SS",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "+500",
    name: "GS",
  },
  {
    value: "Spain",
    label: "+34",
    name: "ES",
  },
  {
    value: "Sri Lanka",
    label: "+94",
    name: "LK",
  },
  {
    value: "Sudan",
    label: "+249",
    name: "SD",
  },
  {
    value: "Surilabel",
    label: "+597",
    name: "SR",
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "+47",
    name: "SJ",
  },
  {
    value: "Swaziland",
    label: "+268",
    name: "SZ",
  },
  {
    value: "Sweden",
    label: "+46",
    name: "SE",
  },
  {
    value: "Switzerland",
    label: "+41",
    name: "CH",
  },
  {
    value: "Syrian Arab Republic",
    label: "+963",
    name: "SY",
  },
  {
    value: "Taiwan",
    label: "+886",
    name: "TW",
  },
  {
    value: "Tajikistan",
    label: "+992",
    name: "TJ",
  },
  {
    value: "Tanzania, United Republic of Tanzania",
    label: "+255",
    name: "TZ",
  },
  {
    value: "Thailand",
    label: "+66",
    name: "TH",
  },
  {
    value: "Timor-Leste",
    label: "+670",
    name: "TL",
  },
  {
    value: "Togo",
    label: "+228",
    name: "TG",
  },
  {
    value: "Tokelau",
    label: "+690",
    name: "TK",
  },
  {
    value: "Tonga",
    label: "+676",
    name: "TO",
  },
  {
    value: "Trinidad and Tobago",
    label: "+1868",
    name: "TT",
  },
  {
    value: "Tunisia",
    label: "+216",
    name: "TN",
  },
  {
    value: "Turkey",
    label: "+90",
    name: "TR",
  },
  {
    value: "Turkmenistan",
    label: "+993",
    name: "TM",
  },
  {
    value: "Turks and Caicos Islands",
    label: "+1649",
    name: "TC",
  },
  {
    value: "Tuvalu",
    label: "+688",
    name: "TV",
  },
  {
    value: "Uganda",
    label: "+256",
    name: "UG",
  },
  {
    value: "Ukraine",
    label: "+380",
    name: "UA",
  },
  {
    value: "United Arab Emirates",
    label: "+971",
    name: "AE",
  },
  {
    value: "United Kingdom",
    label: "+44",
    name: "GB",
  },
  {
    value: "United States",
    label: "+1",
    name: "US",
  },
  {
    value: "Uruguay",
    label: "+598",
    name: "UY",
  },
  {
    value: "Uzbekistan",
    label: "+998",
    name: "UZ",
  },
  {
    value: "Vanuatu",
    label: "+678",
    name: "VU",
  },
  {
    value: "Venezuela, Bolivarian Republic of Venezuela",
    label: "+58",
    name: "VE",
  },
  {
    value: "Vietnam",
    label: "+84",
    name: "VN",
  },
  {
    value: "Virgin Islands, British",
    label: "+1284",
    name: "VG",
  },
  {
    value: "Virgin Islands, U.S.",
    label: "+1340",
    name: "VI",
  },
  {
    value: "Wallis and Futuna",
    label: "+681",
    name: "WF",
  },
  {
    value: "Yemen",
    label: "+967",
    name: "YE",
  },
  {
    value: "Zambia",
    label: "+260",
    name: "ZM",
  },
  {
    value: "Zimbabwe",
    label: "+263",
    name: "ZW",
  },
];
export default countryvalueOptions;
