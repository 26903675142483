import React from "react";
import audino from "../../assetes/Audino192.png";

export const BlogHeding = ({ hedding }) => {
  return (
    <>
      <header className="mb-4 lg:mb-6 not-format">
        <address className="flex items-center mb-6 not-italic">
          <div className="inline-flex items-center mr-3 text-smtext-white">
            <img
              className="mr-4 w-8 h-8 lg:w-12 lg:h-12 rounded-full"
              src={audino}
              alt="audinoAI"
            />
            <div>
              <h1 className="text-xl   text-white">audino AI</h1>
              <p className="text-base text-gray-400 ">
                <time
                  pubdate
                  datetime="2022-02-08"
                  title="February 8th, 2022"
                ></time>
              </p>
            </div>
          </div>
        </address>
      </header>
      <h1 className="mb-4 text-3xl font-extrabold eading-none lg:mb-6 lg:leading-snug  lg:text-6xl text-white">
        {hedding}
      </h1>
    </>
  );
};
