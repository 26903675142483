import React from "react";
import pieSvg from "../../../assetes/pie.svg";
const Api = () => {
  return (
    <section className="bg-[#0F1338]">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 pt-[3rem] ">
        <div className="w-full">
          <div className=" pb-8 lg:pb-0 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-8 h-8  text-sky-500"
            >
              <path
                fillRule="evenodd"
                d="M2.25 13.5a8.25 8.25 0 018.25-8.25 .75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"
                clipRule="evenodd"
              ></path>
              <path
                fillRule="evenodd"
                d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25 .75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="space-y-3 justify-between text-gray-600  md:flex md:flex-row  md:gap-6 md:space-y-0 lg:gap-12 lg:items-center ">
            <div className="md:w-1/2 lg:w-1/2">
              <h2 className="text-3xl font-bold md:text-4xl text-white">
                Seamless Integration with Low-Code APIs
              </h2>
              <p className="my-8  font-thin text-gray-300">
                Empower Your Next AI-Powered Innovation with Our Robust,
                Production-Ready APIs
              </p>
              <div className="divide-y space-y-4 divide-gray-600 ">
                <div className="mt-8 flex gap-4 md:items-center">
                  <div className="w-12 h-12 flex gap-4 rounded-full  bg-teal-900/50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      fill="currentColor"
                      viewBox="0 0 50 50"
                      className="w-6 h-6 m-auto text-teal-400"
                    >
                      <path d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562	L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036	c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"></path>
                    </svg>
                  </div>
                  <div className="w-5/6">
                    <h4 className=" text-lg  text-teal-300">
                      Engineered for Security
                    </h4>
                  </div>
                </div>
                <div className="pt-4 flex gap-4 md:items-center">
                  <div className="w-12 h-12 flex gap-4 rounded-full bg-teal-900/50 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      fill="currentColor"
                      viewBox="0 0 50 50"
                      className="w-6 h-6 m-auto  text-teal-400"
                    >
                      <path d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562	L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036	c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"></path>
                    </svg>
                  </div>
                  <div className="w-5/6">
                    <h4 className=" text-lg text-teal-300">Scalability</h4>
                  </div>
                </div>
                <div className="pt-4 flex gap-4 md:items-center">
                  <div className="w-12 h-12 flex gap-4 rounded-full bg-teal-900/50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      fill="currentColor"
                      viewBox="0 0 50 50"
                      className="w-6 h-6 m-auto text-teal-400"
                    >
                      <path d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562	L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036	c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"></path>
                    </svg>
                  </div>
                  <div className="w-5/6">
                    <h4 className=" text-lg text-teal-300">
                      Compatibility across All Platforms
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 lg:w-1/2 ml-14 ">
              <img
                src={pieSvg}
                alt="api"
                loading="lazy"
                className="w-full"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Api;
