import React from "react";
import { AllblogsButton } from "./Blogs/AllblogsButton";
import Navbar from "./Navbar";

export default function BlogTemplate({ children }) {
  return (
    <section className="text-white">
      <Navbar />
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-[#0F1338] antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-6xl ">
          <article className="mx-auto w-full max-w-6xl format format-sm sm:format-base lg:format-lg format-blue daformat-invert">
            {children}
          </article>
        </div>
        <div className=" text-center ">
          <AllblogsButton />
        </div>
      </main>
    </section>
  );
}
