import React from "react";
import Speechtotext from "../../../assetes/speech-to-text.jpg";
import SpeekerIdentification from "../../../assetes/speekerIdentification.jpg";
import Speechsegmentation from "../../../assetes/SpeechSegmentation.jpg";
import EmotionIdentification from "../../../assetes/EmotionIdentification.jpg";
import SoundEvent from "../../../assetes/SoundEventDetection.jpg";
import Annotation from "../../../assetes/annotation.jpg";
import Lnguage from "../../../assetes/Language.jpg";
import BlogCard from "../../../components/BlogCard";

const BlogCards = () => {
  return (
    <>
      <aside
        aria-label="Related articles"
        className="py-8 lg:py-24  bg-[#0F1338]"
      >
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="  mb-10 lg:mb-14 mt-4 lg:mt-0 text-3xl lg:text-5xl text-center font-bold  bg-gradient-to-r from-[#70CBA2] via-blue-400 to-purple-600 bg-clip-text text-transparent">
            Read Our All Articles
          </h2>
          <div className="grid gap-14 sm:grid-cols-2 lg:grid-cols-4">
            <BlogCard
              title="The Evolution and Impact of Speech-to-Text Technology"
              description="In an age where convenience and efficiency are paramount, speech-to-text technology has become a transformative force across various industries"
              image={Speechtotext}
              href="/blogs/1"
              time={4}
            />
            <BlogCard
              title="Speaker Identification: The Voice Behind the Technology"
              description="In a world where technology is increasingly driven by voice interfaces, speaker identification is a critical component powering personalised and secure experiences."
              image={SpeekerIdentification}
              href="/blogs/2"
              time={3}
            />
            <BlogCard
              title="The Key to Unlocking Effective Communication Technologies"
              description="Speech segmentation is a crucial process in the field of speech and language processing. It involves dividing continuous speech into distinct units such as words, phonemes."
              image={Speechsegmentation}
              href="/blogs/3"
              time={3}
            />
            <BlogCard
              title="The Rise of Emotion Detection"
              description="AI emotion detection analyzes human emotions in real-time, with promising features but ethical challenges."
              image={EmotionIdentification}
              href="/blogs/4"
              time={4}
            />
            <BlogCard
              title="Exploring Sound Event Detection"
              description="Sound Event Detection (SED) identifies and classNameifies environmental sounds, enhancing machine responsiveness and analysis."
              image={SoundEvent}
              href="/blogs/5"
              time={3}
            />
            <BlogCard
              title="Unlocking the Power of Audino Annotation"
              description="Speech-to-Text involves converting audio into text, identifying speakers, emotions, sounds, languages, and phonetic elements."
              image={Annotation}
              href="/blogs/6"
              time={3}
            />
            <BlogCard
              title="The Power of Language Identification in Today's Digital World"
              description="Language identification automatically detects a text's language, enabling seamless communication across linguistic boundaries."
              image={Lnguage}
              href="/blogs/7"
              time={3}
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default BlogCards;
