import React from "react";
import { Link } from "react-router-dom";

export const AllblogsButton = () => {
  return (
    <Link to="/blogs">
      <button className=" mt-[4rem] font-bold px-5 py-3  rounded-lg bg-[#70CBA2] ">
        See All Blogs
      </button>
    </Link>
  );
};
