import React from "react";
import speechPng from "../../../assetes/speech-to-text.png";
import textPng from "../../../assetes/text-to-speech.png";
import translatePng from "../../../assetes/translate.png";
import unstruPng from "../../../assetes/unstructure.png";
import sentimentPng from "../../../assetes/sentiment.png";
import transPng from "../../../assetes/transliteration.png";
function Card({ title, image, text, buttonText }) {
  const isTryNow = buttonText === "Try now";

  return (
    <div className="group bg-[#22295B]   transition hover:z-[1] hover:shadow-lg hover:shadow-gray-700 rounded-tl-[5rem] rounded-br-[5rem]">
      <div className="space-y-6 py-8 p-[25px]">
        <img
          src={image}
          className="w-20"
          width="512"
          height="512"
          alt="Speech-to-Text"
        />

        <div className="space-y-2">
          <h5 className="text-xl font-semibold text-[#70CBA2] group-hover:text-orange-300 transition group-hover:text-secondary">
            {title}
          </h5>
          <p className="text-white text-sm ">{text}</p>
        </div>

        {isTryNow ? (
          <a
            href="https://ai.audino.in/features"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-between group-hover:text-secondary"
          >
            <span className="text-xl text-[#70CBA2] group-hover:text-orange-300">
              {buttonText}
            </span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="orange"
              className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100"
            >
              <path
                fillRule="evenodd"
                d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        ) : (
          <div className="text-xl text-[#70CBA2]">Coming Soon</div>
        )}
      </div>
    </div>
  );
}

const Unifiedplatform = () => {
  return (
    <section className="bg-[#0F1338]">
      <div className="max-w-7xl mx-auto py-7  md:py-10 lg:py-14 px-6 md:px-6 xl:px-4">
        <div className="md:w-2/3 lg:w-1/2">
          <svg
            className="w-8 h-8 text-secondary"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#FFEB3B"
          >
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
              clipRule="evenodd"
            ></path>
          </svg>

          <p className="my-6 text-2xl font-bold text-white    md:text-4xl">
            Consolidating All Your Language AI Services in One Unified Platform{" "}
          </p>
          <p className="text-white font-thin leading-[1.6rem] ">
            With our platform, you can get all your language AI needs in one
            place, saving time and money. Get access to the best language AI
            services, from audio annotation to translation to natural language
            processing, without having to worry about managing multiple vendors.
          </p>
        </div>
        <div className="mt-8 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          <Card
            image={speechPng}
            title="Speech-to-Text"
            text="Live audio transcription in 100 languages"
            buttonText="Try now"
          />
          <Card
            image={textPng}
            title="Text-to-Speech"
            text="Lifelike voice generation in Arabic, Hindi, and English"
          />
          <Card
            image={unstruPng}
            title="Entity Recognition"
            text="Extract, understand and classify data from unstructured text"
          />
          <Card
            image={sentimentPng}
            title="Sentiment analysis"
            text="Detect the sentiment of any sentence"
          />

          <Card
            image={translatePng}
            title="Translation"
            text="Lightning fast translations with up to 99.5% accuracy"
          />
          <Card
            image={transPng}
            title="Transliteration"
            text="Convert text from one alphabet to another"
          />
        </div>
      </div>
    </section>
  );
};

export default Unifiedplatform;
