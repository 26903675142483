import React from "react";
import { Link } from "react-router-dom";

export default function BlogCard({ title, description, image, href, time }) {
  return (
    <Link to={href}>
      <div className="group visible p-4">
        <div className="mb-4 h-[13rem] overflow-hidden ">
          <img
            src={image}
            alt={href}
            className="ease-linear w-full duration-500 scale-100 group-hover:scale-125"
          />
        </div>

        <h4 className="text-white  font-semibold text-xl ease-in duration-300 hover:opacity-80 mb-2">
          {title}
        </h4>
        <p className="mb-2  text-slate-300 font-extralight">{description}</p>
        <div className="flex items-center justify-between gap-4 flex-wrap mt-6">
          <p className="flex items-center gap-2  text-sm flex-wrap border-1 border-white cursor-pointer group-hover:underline group-hover:text-orange-400 text-white">
            Read in {time} minutes
          </p>
        </div>
      </div>
    </Link>
  );
}
