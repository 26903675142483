import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Contactus from "./Pages/Contactus/Contactus";
import Custommodal from "./Pages/CustomModal/Custommodal";
import Blogs from "./components/Blogs";
import Blogs1 from "./components/Blogs1";
import Blogs2 from "./components/Blogs2";
import Blogs3 from "./components/Blogs3";
import Blogs4 from "./components/Blogs4";

import BlogPages from "./Pages/BlogsPage/BlogPages";
import Blogs5 from "./components/Blogs5";
import Blogs6 from "./components/Blogs6";
import ScrollToTop from "./hooks/scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/custom-model" element={<Custommodal />} />
        <Route path="/blogs" element={<BlogPages />} />
        <Route path="/blogs/1" element={<Blogs />} />
        <Route path="/blogs/2" element={<Blogs1 />} />
        <Route path="/blogs/3" element={<Blogs2 />} />
        <Route path="/blogs/4" element={<Blogs3 />} />
        <Route path="/blogs/5" element={<Blogs4 />} />
        <Route path="/blogs/6" element={<Blogs5 />} />
        <Route path="/blogs/7" element={<Blogs6 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
